import React from "react";
import ModuleCard from "../moduleCard/moduleCard";
import shared from '../../../assests/recent.svg';
import "./recent.scss"

const Recent = (props: any) => {
    return (
        <div className="sharedContainer">
            {
                props.recentModules.length === 0 ? <>
                    {/* <div className="noDataContainer flex justify-content-center noDataContainer p-2">
                        <p className="noDataText">No Data Available</p>
                    </div> */}
                </> :
                    <>
                        <p className="title">Recent</p>
                        <div className="flex  px-3 w-100 custom-scorll-carousel carousel">
                            {
                                props.recentModules.map((elm: any, index: any) => {
                                    return (
                                        <ModuleCard key={index} image={shared} module={elm}></ModuleCard>
                                    )
                                })
                            }
                        </div>
                    </>
            }


        </div>
    )
}
export default Recent;