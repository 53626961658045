import { Box, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import './modulelist.scss'
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { ModuleListStoreImpContext } from '../../../store/moduleListStore'
import { observer } from "mobx-react";
import Loading from "../../utilities/loading/loading";
import { ModuleDetail } from "../moduleDetail/moduleDetail";
import { Module } from "../../../store/moduleReviewApi";
import { useLocation, useNavigate } from "react-router";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Tooltip from '@mui/material/Tooltip';
import InnerModuleDetail from "../moduleDetail/InnerModuleDetail";
interface routerState {
    moduleId: string
}

export const ModuleList: React.FC<{}> = observer(({ }) => {
    const [loadingData, setLoadingData] = useState(true)
    const [selectedModule, setSelectedModule] = useState<Module | null>(null);
    const [favorite, setFavorite] = useState<Boolean>(false);
    const [role,setRole] = useState<string[]>([])
    const store = useContext(ModuleListStoreImpContext)
    const navigate = useNavigate()
    const location = useLocation();
    const state = location.state as routerState

    async function fetchRoles() {
        if (state.moduleId) {
            await store.fetchRoles(+state.moduleId).then(res => {
                setRole(res)
            })
        }
    }
    const fetchModule = async () => {
        const elem = await store.fetchModule(+state.moduleId);

        try {
            setSelectedModule(elem);
            setFavorite(elem.isBookmarked);
            setLoadingData(false);
        }
        catch (err) {
            navigate('/error');
            console.log(err);
        }
    }
    useEffect(() => {
        fetchRoles()
        fetchModule()
    }, [])
   
    useEffect(() => {
        fetchModule()
    }, [store.markAsCompleteClicked])

    return loadingData ? (<div className="App">
        <Loading></Loading>
    </div>) : (
        <div className="fadeindownAnimation">
            <div>
                <p className="title">Module Details</p>
            </div>
            <div className="flex justify-content-end w-100">
                <IconButton size="small" onClick={async () => {
                    if (selectedModule?.id) {
                        const result = await store.bookMarkModule(selectedModule.id)
                        setFavorite(result)
                    }
                }}>
                    {
                        favorite ? <>
                            <Tooltip title="Remove from favorite">
                                <FavoriteIcon fontSize="medium" className="taskIcon red-Color"></FavoriteIcon>
                            </Tooltip>

                        </> :
                            <>
                                <Tooltip title="Add to favorite">
                                    <FavoriteBorderIcon fontSize="medium" className="taskIcon grayColor"></FavoriteBorderIcon>
                                </Tooltip>
                            </>
                    }

                </IconButton>
            </div>
            <Box
                className="maincontainer white-Background"
            >
                <TableContainer component={Paper} className='tableContainer'>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead className="tableHeader">
                            <TableRow>
                                <TableCell className="tableTitle">Title</TableCell>
                                <TableCell align="center" className="tableTitle">Module Department</TableCell>
                                <TableCell align="center" className="tableTitle">Credit</TableCell>
                                <TableCell align="center" className="tableTitle">Students</TableCell>
                                <TableCell align="center" className="tableTitle">Status</TableCell>
                                <TableCell align="center" className="tableTitle">Last Updated</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                !selectedModule &&
                                <TableRow>
                                    <TableCell colSpan={6}>
                                        <p className="dateButton margin-remove-bottom">No Data Available</p>
                                    </TableCell>

                                </TableRow>
                            }
                            {
                                <TableRow className="tableRow"
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th">
                                        <div>
                                            <p className="programTitle">{selectedModule!.code}</p>
                                            <p className="programSubTitle">{selectedModule!.title}</p>
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">
                                        <span className="programTitle">
                                            {selectedModule!.department}
                                        </span>
                                    </TableCell>
                                    <TableCell align="center" >
                                        <span className="programTitle">
                                            {selectedModule!.credit}
                                        </span></TableCell>
                                    <TableCell align="center">
                                        <div className="numberContainer">
                                            <span className="margin-remove-bottom mr-2 programCellTitle">{selectedModule!.studentCount}</span>
                                            {
                                                selectedModule!.studentCountChange! > 0 ? <>
                                                    <div className="increaseArrowContainer">
                                                        <ArrowUpwardIcon fontSize="small" />
                                                        <p className="flex flex-shrink-0 margin-remove-bottom">{selectedModule!.studentCountChange} %</p>


                                                    </div>

                                                </> :
                                                    selectedModule!.studentCountChange! < 0 ? <>
                                                        <div className="decreaseContainer">
                                                            <ArrowDownwardIcon fontSize="small" />
                                                            <p className="flex flex-shrink-0 margin-remove-bottom">{selectedModule!.studentCountChange} %</p>


                                                        </div>
                                                    </> : <span className="flex flex-shrink-0 margin-remove-bottom neutralContainer">0</span>

                                            }

                                        </div>
                                    </TableCell>
                                    <TableCell align="center">{
                                        selectedModule!.reviewStatus! === 2 ? <>
                                            <div className="actionContainer flex p-1 text-uppercase">
                                                <CheckCircleIcon fontSize="small" className="completedIcon" />
                                                <span className="programCellTitle">
                                                    Completed
                                                </span>
                                            </div>
                                        </> :
                                            <>
                                                <div className="actionContainer flex p-1 text-uppercase">
                                                    <InfoIcon fontSize="small" className="actionIcon" />
                                                    <span className="programCellTitle">
                                                        Action Required
                                                    </span>
                                                </div>
                                            </>

                                    }

                                    </TableCell>
                                    <TableCell align="center"><span className="programTitle">
                                        {selectedModule!.lastUpdated && moment(new Date(selectedModule!.lastUpdated!.toDateString())).format("LLL")}
                                    </span></TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <div>
                <InnerModuleDetail module={selectedModule} store={store} role={role} ></InnerModuleDetail>
                <ModuleDetail module={selectedModule}></ModuleDetail>
            </div>
        </div>
    );
})