import { Box, Fab, CircularProgress, DialogActions, Dialog, Button, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { Actions } from "../../../../../store/moduleReviewApi";
import { red } from "@mui/material/colors";
import DeleteIcon from '@mui/icons-material/Delete';

export interface SimpleDialogProps {
    open: boolean;
    action: Actions
    onClose: (value: string) => void;
}

const Delete = (props: any) => {
    const [open, setOpen] = React.useState(false);
    const [actiondeleteLoading, setactiondeleteLoading] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const deleteActions = async (action: Actions) => {
        handleClose();
        await props.store.deleteActions(action)
        setactiondeleteLoading(false)
        props.deleteEvent()
    }
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Box sx={{ m: 0, position: 'relative' }}>
                <Fab className="actionButton"
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                        handleClickOpen()
                    }}
                >
                    <DeleteIcon fontSize="small" className="m-2 buttonIcon" />
                </Fab>
                {actiondeleteLoading && (
                    <CircularProgress
                        size={45}
                        sx={{
                            color: red[500],
                            position: 'absolute',
                            top: -3,
                            left: -3,
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    Delete Action?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to delete this action?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        setactiondeleteLoading(true)
                        deleteActions(props.action)
                    }} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )


}
export default Delete;