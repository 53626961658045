import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import './addAction.scss';
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import PeoplePicker from "../../../../utilities/peoplePicker/peoplePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DatePickerUtil from "../../../../utilities/datePicker/datePicker";
import QuillEditor from "../../../../editing/QuillEditor";
import Quill from "quill/core";
import { ModuleListStoreImpl } from "../../../../../store/moduleListStore";
import { Actions, Person } from "../../../../../store/moduleReviewApi";
import quillHelper from "../../../../../helpers/DeltaHelper";

type AddActionProps = {
    assignAction: boolean; 
    mode: string; 
    question: number; 
    occurence: any; 
    refresh: () => void; 
    store: ModuleListStoreImpl; 
    id?: number; 
    action: Actions | null; 
    editorComplete: () => void; 
    initialValue?: string 
};

const AddAction = (props: AddActionProps) => {
    const quillRef = useRef<Quill | null>();
    const [submitting, setSubmitting] = useState(false)
    const [selectedUserList, setSelectedUserList] = useState<Person[]>([])
    const [dateValue, setDateValue] = useState<any>()

    const handleChange = (newValue: any) => {
        setDateValue(newValue);
    };

    const reset = () => {
        setDateValue("")
        setSelectedUserList([])
        setSubmitting(false)
    };

    const getActionText: () => string = () => quillHelper.getHtmlString(quillRef.current!);

    const quillEditorLoaded: () => boolean = () => {
        if (quillRef)
            if (quillRef.current)
                return true;

        return false;
    }

    const validation = () => {
        if (quillEditorLoaded()) {
            var description = getActionText();
            if (props.assignAction){
                return dateValue !== null && selectedUserList.length > 0 && description.length > 0 
            }
            else{
                return description.length > 0 
            }
        }

        return true;
    };

    const clearEditor = () => {
        if (quillEditorLoaded()) 
            quillHelper.loadHtmlString(quillRef.current!, "");
    }

    const addActionClick = async () => {
        var description = getActionText();
        setSubmitting(true)
        if (props.mode === "add") {
            const action = {
                Description: description,
                Question: props.question,
                ModuleRoleId: props.occurence,
                LastUpdated: new Date().toISOString(),
                Deleted: false,
                Due: dateValue ? new Date(dateValue).toISOString() : null,
                Assignee:selectedUserList
            }
            await props.store.addAction(action)
            reset()
            clearEditor();
            props.refresh()
        }
        else {
            var _action = props.action!;
            const action = {
                id: props.id,
                Description: description,
                question: _action.question,
                LastUpdated: new Date().toISOString(),
                Deleted: _action.deleted,
                Due:dateValue ? new Date(dateValue).toISOString() : null,
                assignee:selectedUserList
            }
            await props.store.editAction(action as unknown as Actions)
            setSubmitting(false)
            clearEditor();
            props.editorComplete()
        }
    };

    const cancelActionClick = async () => {
        reset()
        if (props.mode === "add") {
            clearEditor();
        }
        else {
            clearEditor();
            props.editorComplete()
        }
    };

    useEffect(() => {
        quillHelper.loadHtmlString(quillRef.current!, "");
    }, [submitting]);

    const placeholderText = 'Add your comment here...';

    useEffect(() => {
        if (quillRef?.current)
            quillHelper.loadHtmlString(quillRef.current!, props.initialValue ?? "");
    }, [props.initialValue]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className="">
                {
                    props.assignAction && <>
                        <div className="flex justify-content-between mb-4 mt-2">
                            <div className="mr-4 w-75">
                                <label className="font-weight-bold">Assignee:</label>
                                <PeoplePicker currentUsers={[]} selectedUsers={(e: any) => {
                                    setSelectedUserList(e)
                                }}></PeoplePicker>
                            </div>
                            <div className="flex flex-column flex-lg-grow-1">
                                <label className="font-weight-bold">Due:</label>
                                <DatePickerUtil assignDate={handleChange}></DatePickerUtil>
                            </div>
                        </div>
                    </>
                }
                {/* make sure that you remove a lot of the options from the configuration */}
                <QuillEditor placeholder={placeholderText} ref={quillRef as unknown as RefObject<Quill>} readOnly={false} defaultValue={props.initialValue} onSelectionChange={(_x,_y,_Z) => {}} onTextChange={(_x,_y,_Z) => {}} />
                <div className="flex justify-content-end py-2">
                    <Button variant="text" className="grayColor mr-2" onClick={cancelActionClick}>Cancel</Button>
                    <LoadingButton className=""
                        loading={submitting}
                        loadingPosition="start"
                        disabled={!validation()}
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        onClick={addActionClick}
                    >Save</LoadingButton>
                </div>
            </div>
        </LocalizationProvider>
    )
}
export default AddAction;