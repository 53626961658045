import React, { useEffect, useState } from "react";
import { DialogActions, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Switch, styled } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';


interface MarkAsCompleteProps {
    id?: number,
    status?: number,
    store: any
}

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));
  



const MarkAsComplete: React.FC<MarkAsCompleteProps> = ({ id, status, store }) => {
    const [open, setOpen] = React.useState(false);
    const [actionLoading, setactionLoading] = useState(false);
    const [checkedValue, setCheckedValue] = useState(false)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const markAsComplete = () => {
        id && store.markAsComplete(id).then((_elm: any) => {
            setCheckedValue(!checkedValue)
            handleClose()
            setactionLoading(false)
        })

    }
    useEffect(() => {
        status === 1 ? setCheckedValue(false) : setCheckedValue(true)
    }, [])


    return (
        <div>
            {
                <div className="align-items-center flex flex-row justify-content-center">
                    <p className="font-weight-bold margin-remove-bottom">Mark as Complete</p>
                    <Android12Switch aria-label='Mark as Complete' checked={checkedValue} onChange={
                        () => {
                            if (status === 1) {
                                setactionLoading(true)
                                handleClickOpen()
                            }
                            else{
                                setactionLoading(true)
                                markAsComplete()
                            }

                        }
                    } />
                </div>


                //     status === "1" ?
                // <div className="align-items-center flex flex-row justify-content-center">
                //     <p className="font-weight-bold margin-remove-bottom">Mark as Complete</p>
                //     <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                //     {/* <Checkbox  defaultChecked size="small" /> */}
                //     {/* <Switch aria-label='Mark as Complete' checked={checkedValue} onChange={
                //                 () => {
                //                     setactionLoading(true)
                //                     handleClickOpen()
                //                 }
                //             } /> */}
                // </div>
                // :
                // <div className="align-items-center flex flex-row justify-content-center">
                //     <p className="font-weight-bold margin-remove-bottom">Mark as In Progress</p>
                //     <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                //     {/* <Checkbox defaultChecked size="small" /> */}
                //     {/* <Switch aria-label='Mark as Complete' checked={checkedValue} onChange={() => {
                //                 console.log(checkedValue)
                //                 setCheckedValue(false)
                //                 setactionLoading(true)
                //                 markAsComplete()
                //             }} /> */}
                // </div>


            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="complete-dialog-title"
            >
                <DialogTitle id="complete-dialog-title">
                    Complete the Module?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to mark this module as complete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleClose()
                        setCheckedValue(false)
                    }
                    }>
                        No
                    </Button>
                    <Button onClick={() => {

                        markAsComplete()
                    }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    )

}
export default MarkAsComplete