import React from "react";
import ModuleCard from "../moduleCard/moduleCard";
import "./myModules.scss"
import shared from '../../../assests/sharedModule.jpg';

const MyModules = (props: any) => {
    return (
        <div className="sharedContainer">
            <p className="title">My Modules</p>
            {
                props.myModules.length === 0 ? <>
                    <div className="noDataContainer flex justify-content-center noDataContainer p-2">
                        <p className="noDataText">No Data Available</p>
                    </div>
                </> :
                    <>
                        <div className="flex  px-3 w-100 custom-scorll-carousel carousel">
                            {
                                props.myModules.map((elm: any, index: any) => {
                                    return (
                                        <ModuleCard key={index} image={shared} module={elm}></ModuleCard>
                                    )
                                })
                            }
                        </div>
                    </>
            }


        </div>
    )
}
export default MyModules;