import { Alert, Slide, SlideProps, Snackbar } from "@mui/material";
import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";

export type SnackbarUtilRef = {
    open: () => void;
}

export type SnackbarUtilProps = {
    message: string
}

const SnackbarUtil = forwardRef<SnackbarUtilRef, SnackbarUtilProps>((props, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () => {
        setIsOpen(false)
    };

    useImperativeHandle(ref, () => {
        return {
            open: () => {
                setIsOpen(true);
            }
        }
    });

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={2300}
            onClose={handleClose}
            message={props.message}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        />
    )
});

export default SnackbarUtil;