import { action, computed, makeObservable, observable, runInAction } from "mobx"
import moment from "moment"
import { ModulesClient, Module, Lumes, LumesClient, AssessmentOutcomesClient, ActionsClient, Actions, AssessmentOutcome, ActionHistory, RolesClient, PDFClient, QuestionsClient, EvasysClient, ThematicQuestionClient, ThematicQuestionModel, GenericResult } from "./moduleReviewApi"
import { createContext, useContext } from "react"
import ClientCreator from "./clientCreator";
import { IPublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";

export class ModuleListStoreImpl {
    moduleListData: Module[] = [];
    userName:string = ""
    moduleFilteredData: Module[] = [];
    BookMarkedModules: Module[] = [];
    RecentModules: Module[] = [];
    LumesData: Lumes[] = [];
    roles: string[] = []
    assessmentOutcomes: AssessmentOutcome[] = [];
    actionsData: Actions[] = [];
    moduleReview?: Module;
    questionsTobeCompleted: number[] = []
    markAsCompleteClicked!: boolean

    tokenClient: ClientCreator;

    moduleApi: ModulesClient;
    lumesApi: LumesClient;
    evasysApi: EvasysClient;
    assessmentOutcomesApi: AssessmentOutcomesClient;
    actionsApi: ActionsClient;
    thematicQuestionApi: ThematicQuestionClient;
    rolesApi: RolesClient;
    pdfApi: PDFClient;
    questionApi: QuestionsClient
    graphApi: Client;


    constructor(msal: IPublicClientApplication, apiRequest: RedirectRequest) {
        this.tokenClient = new ClientCreator(msal, apiRequest)
        this.moduleApi = this.tokenClient.createClient(ModulesClient)
        this.lumesApi = this.tokenClient.createClient(LumesClient)
        this.evasysApi = this.tokenClient.createClient(EvasysClient)
        this.actionsApi = this.tokenClient.createClient(ActionsClient)
        this.thematicQuestionApi = this.tokenClient.createClient(ThematicQuestionClient)
        this.assessmentOutcomesApi = this.tokenClient.createClient(AssessmentOutcomesClient)
        this.rolesApi = this.tokenClient.createClient(RolesClient)
        this.pdfApi = this.tokenClient.createClient(PDFClient)
        this.questionApi = this.tokenClient.createClient(QuestionsClient)
        this.graphApi = this.tokenClient.createGraphClient()
        makeObservable(this, {
            moduleListData: observable,
            moduleFilteredData: observable,
            BookMarkedModules: observable,
            RecentModules: observable,
            roles: observable,
            markAsCompleteClicked: observable,
            LumesData: observable,
            questionsTobeCompleted: observable,
            assessmentOutcomes: observable,
            actionsData: observable,
            lumesQuestionsFetch: action,
            assessmentOutcomesFetch: action,
            dateFilteration: action,
            searchModules: action,
            fetchBookMarkedModules: action,
            fetchMyRecentModules: action,
            bookMarkModule: action,
            actionsFetch: action,
            recentModule: action,
            setActions: action,
            fetchRoles:action,
            markAsComplete: action,
        });
    }

    async getToken() {
        await this.tokenClient.getToken();
    }
    async lumesQuestionsFetch(id: number) {
        return await this.lumesApi.getLumesDetails(id);
    }

    async evasysFetch(id: number) {
        return await this.evasysApi.getEvasysResponses(id);
    }

    async fetchBookMarkedModules() {
        return await this.moduleApi!.getBookMarkedModules();
    }
    async fetchUsers(email:string) {
        return await this.graphApi.api(`/users/${email}`).get();
    }
    async checkUser(email:string) {
        return await this.moduleApi.checkUser(email);
    }
    setActions(actionsList: number[]) {
        this.questionsTobeCompleted = [...actionsList]
    }
    downloadPdfAction(id: number) {
        return this.pdfApi.pdfGenerator(id)
    }
    async fetchMyModules() {
        return await this.moduleApi!.getMyModules();
    }
    async fetchQuestions() {
        return await this.questionApi!.getQuestionsDetails();
    }
    async markAsComplete(id: number) {
        const result = await this.moduleApi!.markAsComplete(id);
        this.markAsCompleteClicked = result
    }

    fetchRoles = async (id:number) => {
        return await this.rolesApi.getRolesOccurence(id)
    }

    assginConvenors(request: any) {
        return this.moduleApi.assignConvenors(request)
    }

    async fetchConvenors(id: number) {
        return await this.rolesApi.getConvernors(id)
    }
    async fetchSharedPeople(id: number) {
        return await this.rolesApi.getShared(id)
    }
    async searchPeoples(term: string) {
        return await this.rolesApi.searchPeople(term)
    }
    async fetchMyRecentModules() {
        return await this.moduleApi!.getMyRecentModules();
    }

    async assessmentOutcomesFetch(id: number) {
        this.assessmentOutcomes = await this.assessmentOutcomesApi.getOutcomes(id);
    }

    async fetchQuestionIds() {
        return await this.actionsApi.getQuestionIds();
    }

    async actionsFetch(id: number) {
        this.actionsData = await this.actionsApi.getActionsDetails(id);
    }

    async fetchThematicQuestion(moduleId: number): Promise<ThematicQuestionModel> {
        return await this.thematicQuestionApi.getThematicQuestion(moduleId);
    }
    async setThematicAnswer(moduleId: number, answerHtml: string): Promise<GenericResult> {
        return await this.thematicQuestionApi.setThematicAnswer(moduleId, answerHtml);
    }
    async fetchActionHistory(questionId: number, moduleId: number) {
        return await this.actionsApi.getActionHistoryDetails(questionId, moduleId)
    }
    async deleteActions(action: Actions) {
        await this.actionsApi.removeAction(action);
    }

    async editAction(action: Actions) {
        await this.actionsApi.editAction(action);
    }

    async bookMarkModule(id: number) {
        return await this.moduleApi.bookmarkmodule(id);
    }
    async addAction(action: any) {
        return await this.actionsApi.insertActions(action);
    }
    async recentModule(id: number) {
        return await this.moduleApi.recentmodule(id);
    }

    async userIsConvenorOfModule(moduleId: number) {
        return await this.actionsApi.userIsConvenorOfModule(moduleId);
    }

    async dateFilteration(year: string) {
        this.moduleFilteredData = []
        this.moduleFilteredData = await this.moduleListData!.filter(elem => elem.year === year);

    }

    async fetchModule(id: number) {
        return await this.moduleApi!.getModule(id);
    }

    async searchModules(searchTerm: string) {
        return await this.moduleApi.searchModules(searchTerm);
    }
}
export const ModuleListStoreImpContext = createContext<ModuleListStoreImpl>({} as ModuleListStoreImpl)
