import { Dialog, DialogContent, DialogActions, styled, Avatar, autocompleteClasses, Card, Autocomplete, TextField, alpha, OutlinedInputProps, TextFieldProps, Chip, IconButton } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import Button from '@mui/material/Button';
import sharePic from "../../../../../assests/shared.svg"
import './admin.scss'
import profilePic from '../../../../../assests/profilePic.svg'
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { ModuleListStoreImpContext } from "../../../../../store/moduleListStore";
import { IRolesRequest, RolesRequest } from "../../../../../store/moduleReviewApi";
import { LoadingButton } from "@mui/lab";
import SnackBarUtil from "../../../../utilities/snackBar/snackBar";
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PeoplePicker from "../../../../utilities/peoplePicker/peoplePicker";

const RedditTextField = styled((props: TextFieldProps) => (
    <TextField size="small"
        InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiFilledInput-root': {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        paddingTop: 10,
        backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const AdminAddition = (props: any) => {
    const store = useContext(ModuleListStoreImpContext)
    const [open, setOpen] = useState(false)
    const [usersList, setUsersList] = React.useState<any[]>([]);
    const [searchUserList, setSearchUserList] = React.useState<any[]>([]);
    const [selectedUserList, setSelectedUserList] = useState<any[]>([])
    const [loadingShare, setLoadingShare] = useState(false)

    const search = async (e: any) => {
        setSearchUserList([])
        if (e.target.value) {
            await store.searchPeoples(e.target.value).then(result => {
                if (result.length > 0) {
                    setSearchUserList(result)
                }
            })
        }

    }
    const handleClose = () => {
        setOpen(false)
    }
    const shareRoles = () => {
        const listIds: number[] = []
        selectedUserList.forEach(user => {
            listIds.push(user.id)
        })
        const requestBody: IRolesRequest = {
            moduleId: props.id,
            occurenceRoleType: 2,
            personId: listIds
        }
        store.assginConvenors(requestBody).then(result => {
            if (result) {
                props.convenorAction(selectedUserList)
                handleClose()
            }
            setLoadingShare(false)
        })
    }
    useEffect(() => {
        setOpen(false)
        if (props.convenors) {
            setUsersList([...props.convenors])
        }

    }, [])

    return (
        <>
            <IconButton aria-label="add" className="addIconButton" size="small" onClick={() => {
                setOpen(true)
            }}>
                <PersonAddRoundedIcon className="iconColor" fontSize="small" titleAccess="Add assignee" />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="share-dialog-title"
                className="dialogContent"
            >
                <DialogContent>
                    <div className="dialogContent">
                        <div className="flex justify-content-between">
                            <div>
                                <h1 className="shareTitle">Add members to your project</h1>
                                <p className="detailGrayTitle m-1 pl-1 px-0">You can add members to your module!Invite your team to this module</p>
                            </div>
                            <img src={sharePic} className="imageHeight" alt="shareImage" />


                        </div>
                        <div className="mt-2 px-2">
                            <label className="font-weight-bold">Invite team members</label>
                            <PeoplePicker currentUsers={usersList} selectedUsers={(e:any) => {
                                setSelectedUserList(e)
                            }}></PeoplePicker>
                        </div>
                        <div className="mt-2 px-2">
                            <label className="font-weight-bold">Module Convenors</label>
                            <Card className="currentUserList ml-2">
                                {
                                    props.convenors.map((user: any, index: number) => {
                                        if(user && user.firstname !== undefined){
                                            return (
                                                <div key={index}>
                                                    <div className="flex m-2">
                                                        <Avatar src={profilePic} />
                                                        <div className="pl-2">
                                                            <div className="flex">
                                                                <p className="font-weight-bold margin-remove-bottom">{user.firstname} {user.surname}</p>
                                                                {
                                                                    user.currentPerson &&
                                                                    <div className="align-items-center currentUserContainer flex justify-content-center ml-2">
                                                                        <p className="align-items-center currentUser flex justify-content-center margin-remove-bottom">You</p>
                                                                    </div>
    
                                                                }
    
                                                            </div>
                                                            <p className="margin-remove-bottom programSubTitle">{user.email}</p>
    
                                                        </div>
    
                                                    </div>
    
                                                </div>
                                            )
                                        }
                                       
                                    })
                                }

                            </Card>
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton loading={loadingShare}
                        loadingPosition="start"
                        className="mr-3"
                        startIcon={<SendRoundedIcon />} onClick={() => {
                            setLoadingShare(true)
                            shareRoles()
                        }} autoFocus variant="contained">
                        Add
                    </LoadingButton>

                </DialogActions>
            </Dialog>
        </>
    )
}
export default AdminAddition