import { AccountInfo, EventType, InteractionType, IPublicClientApplication, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { AuthenticationProvider, Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider, AuthCodeMSALBrowserAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser/index";
//import { AuthCodeMSALBrowserAuthenticationProvider, AuthCodeMSALBrowserAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

export default class ClientCreator {
    msalContext: IPublicClientApplication;
    apiRequest: RedirectRequest;
    constructor(msal: IPublicClientApplication, apiRequest: RedirectRequest) {
        this.msalContext = msal;
        this.apiRequest = apiRequest
    }

    public async getToken(): Promise<string> {
        //const accounts = this.msalContext.getAllAccounts()
        

        const accounts = this.msalContext.getAllAccounts();
        if (accounts.length > 0) {
            this.msalContext.setActiveAccount(accounts[0]);
        }

        const request = {
            ...this.apiRequest,
            account: accounts[0]
        };

        this.msalContext.addEventCallback((event: any) => {
            // set active account after redirect
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                const account = event.payload.account;
                this.msalContext.setActiveAccount(account);
            }
        });


        try {
            const tokenResponse = await this.msalContext.acquireTokenSilent(request)
            return tokenResponse.accessToken;
        }
        catch (error) {
            this.msalContext.handleRedirectPromise().then(authResult=>{
                const account = this.msalContext.getActiveAccount();
                if(!account){
                  this.msalContext.loginRedirect(request);
                }
              }).catch(err=>{
                console.log(err);
              });
            return "";
        }

    }

   

    public createGraphClient = (): Client => {
        const accounts = this.msalContext.getAllAccounts();
        if (accounts.length > 0) {
            this.msalContext.setActiveAccount(accounts[0]);
        }

        const account = accounts[0];
        

        const options:AuthCodeMSALBrowserAuthenticationProviderOptions = {
            account: account, // the AccountInfo instance to acquire the token for.
            interactionType: InteractionType.Popup , // msal-browser InteractionType
            scopes: ["user.read", "user.read.all"] // example of the scopes to be passed
        }
        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(this.msalContext as PublicClientApplication, options);
        const graphClient = Client.initWithMiddleware({
            authProvider
        });

        return graphClient;
    }

   
    public createClient<T>(ctor: { new(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }): T }): T {
        const _baseUrl = window.location.protocol + "//" + window.location.host
        return new ctor(_baseUrl, {
            fetch: async (url: RequestInfo, options: RequestInit) => {
                const token = await this.getToken();
                const authorization = { authorization: `Bearer ${token}` };
                const fetchOptions = { ...options, headers: { ...options.headers, ...authorization } };
                return window.fetch(url, fetchOptions);
            }
        });
    }
}

