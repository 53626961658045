const titleWords = (title:string) => {

        title = title.replaceAll('(', '').replaceAll(')', '');
        
        const words = title.split(/[ ]+/);
        if(words.length > 2){
            if(words[0].toLowerCase() === 'the'){
                return `${words[1][0]}${words[words.length-1][0]}`
            }
            else{
                return `${words[0][0]}${words[words.length-1][0]}`
            }
        }
        else if(words.length === 2){
            return `${words[0][0]}${words[1][0]}`
        }
        else{
            return `${words[0][0]}${words[0][1]}`
        }
    }

    export default titleWords;