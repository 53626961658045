import React from "react";
import { Module } from "../../../../store/moduleReviewApi";
import { FiltersAccordion } from "../filters/filter";
import { ResultCard } from "./resultCard/resultCard";
import './result.scss'
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import SortIcon from '@mui/icons-material/Sort';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface resultsProps {
    modules: Module[]
}

export const Results = (props: resultsProps) => {
    return (
        <div className="flex fadeindownAnimation">
            <div className="filterContainer w-25">
                {/* <div className="flex justify-content-between">
                    <p className="filterText">FilterBy</p>
                    <Button
                        variant="text" size="small" className="red-Color text-capitalize"
                    >Reset Filters</Button>
                </div>
                <Divider className="mb-3 dividerColor" />
                <div className="">
                    <FiltersAccordion name="By Department" options={['Mechanical', 'Computer Science', 'Arts', 'Physics']}></FiltersAccordion>
                    <FiltersAccordion name="By Level" options={['Postgraudate', 'Undergraduate', 'Foundation', 'Distance Learning']}></FiltersAccordion>
                    <FiltersAccordion name="By School" options={['School of Engineering', 'School of Business', 'Mathematical Science', 'Computing']}></FiltersAccordion>
                </div> */}
            </div>
            <div className="filterContainer ml-4">
                <div className="align-items-center flex justify-content-md-between pl-2 resultTitle">
                    <p className="margin-remove-bottom">{props.modules.length} results</p>
                    <Button variant="outlined"  className='sortIcon' startIcon={<SortIcon fontSize='small' />} endIcon={<KeyboardArrowDownIcon fontSize='small' />}>
                        Sort By
                    </Button>
                </div>
                <div className="flex flex-wrap w-100">

                    {props.modules.map((module: Module, index) => <ResultCard key={index} module={module}></ResultCard>)}

                </div>
            </div>

        </div>
    )
}