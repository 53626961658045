import React from "react";
import { useMsal } from "@azure/msal-react";
//import { loginRequest } from "../../authConfig";
import Button from '@mui/material/Button';
import { IPublicClientApplication } from "@azure/msal-browser";
import { storedAuthConfig } from '../../AuthConfigProvider';

function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(storedAuthConfig.loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <Button variant="outlined" onClick={() => handleLogin(instance)}>Sign in</Button>
    );
} 