import * as React from 'react';
import './loading.scss'

export default function Loading() {
    return (
        <div className='loadingContainer' id="loadingContainer">
            <div className='loader'>
                <div>
                    <span className='loadingBar'></span>
                    <span className='loadingBar'></span>
                    <span className='loadingBar'></span>
                    <span className='loadingBar'></span>
                </div>
            </div>
        </div>
    );
}