import { Avatar, AvatarGroup, Button, Card, Popover } from "@mui/material";
import React from "react"
import "./avatar.scss"
import profilePic from '../../../../../assests/profilePic.svg'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';


function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: 24, height: 24,
            fontSize: 12
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


const AvatarList = (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <AvatarGroup max={3}>
            {
                props.userList.map((elm: any, index: any) => {
                    if(elm && elm.firstname !== undefined){
                        return (
                            <div key={index} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}
                            >
                                <Avatar  {...stringAvatar(`${elm.firstname} ${elm.surname}`)} />
                            </div>
    
                        )
                    }
                   
                })
            }
        </AvatarGroup>
    )
}

export default AvatarList