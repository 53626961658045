import React, { ForwardedRef, forwardRef, MutableRefObject, ReactNode, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import { Delta, EmitterSource, Range } from 'quill/core';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import './QuillEditor.css';

// Editor is an uncontrolled React component
const Editor = forwardRef<Quill, {
    children?: ReactNode;
    readOnly: boolean;
    placeholder?: string;
    defaultValue: string | null | undefined;
    onTextChange: (delta: Delta, oldContent: Delta, source: EmitterSource) => void;
    onSelectionChange: (range: Range, oldRange: Range, source: EmitterSource) => void;
}>(
  ({ readOnly, placeholder, defaultValue, onTextChange, onSelectionChange }, ref : ForwardedRef<Quill>) => {

    const containerRef = useRef<HTMLDivElement | null>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
        const castedRef = ref as MutableRefObject<Quill | null>;
        if (castedRef?.current)
            castedRef.current.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
        if (containerRef.current == null)
            return;       
        const container = containerRef.current;
        const innerDiv = container?.ownerDocument?.createElement('div');
        const editorContainer = container?.appendChild(innerDiv as HTMLElement) as HTMLElement;
        const quill = new Quill(editorContainer, {
            theme: 'snow',
            placeholder: placeholder
        });

        const castedRef = ref as MutableRefObject<Quill | null>;
        if (castedRef)
        {
            castedRef.current = quill;
        }

        if (defaultValueRef.current) {
            const _defaultValue = defaultValue as string | undefined;
            const defaultValueAsDelta = quill.clipboard.convert({ html: _defaultValue });
            quill.setContents(defaultValueAsDelta);
        }

        quill.on(Quill.events.TEXT_CHANGE, (delta, oldDelta, source) => {

            onTextChangeRef.current?.(delta, oldDelta, source);
        });

        // to get the html of a quill do:
        // quill.root.innerHTML
        quill.on(Quill.events.SELECTION_CHANGE, (range, oldRange, source) => {
            onSelectionChangeRef.current?.(range, oldRange, source);
        });

        return () => {
            castedRef.current = null;
            if (container) container.innerHTML = '';
        };
    }, [ref]);

    return <div ref={containerRef}></div>;
  },
);

Editor.displayName = 'Editor';

export default Editor;