import { RedirectRequest, Configuration } from "@azure/msal-browser";
import { AuthConfigClient } from './store/moduleReviewApi';

type AuthConfig = { msalConfig : Configuration; loginRequest: RedirectRequest }
class AuthConfigProvider {
    getAuthConfig: () => Promise<AuthConfig> = async () => {

        const _baseUrl = window.location.protocol + "//" + window.location.host;

        var client = new AuthConfigClient(_baseUrl);
        var authConfig = await client.getAuthConfig();

        const msalConfig : Configuration = {
            auth: {
                clientId: authConfig.spaClientId as string,
                // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
                authority: `https://login.microsoftonline.com/${authConfig.tenantId}`,
                redirectUri: "/signin-oidc",
            },
            cache: {
                cacheLocation: "localStorage", // This configures where your cache will be stored
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            }
        };

        const loginRequest : RedirectRequest = {
            authority: `https://login.microsoftonline.com/${authConfig.tenantId}`,
            scopes: [`api://${authConfig.apiClientId}/${authConfig.scopes}`,]

        }

        return { msalConfig, loginRequest };
    }
}

const storedAuthConfig : AuthConfig = { } as AuthConfig;

export { storedAuthConfig };
export { AuthConfigProvider };
export type { AuthConfig };
