import React, { useContext, useState, useEffect, useRef, RefObject } from "react";
import Favorite from "./favorites/favorite";
import "./dashboard.scss"
import { ModuleListStoreImpContext } from "../../store/moduleListStore";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import { Module } from "../../store/moduleReviewApi";
import Loading from "../utilities/loading/loading";
import MyModules from "./pinned/myModules";
import Recent from "./recent/recent";
import { useMsal } from "@azure/msal-react";

export const Dashboard: React.FC<{}> = observer(({ }) => {
    const store = useContext(ModuleListStoreImpContext)
    const { accounts } = useMsal();
    const [loadingData, setLoadingData] = useState(true)
    const [favoriteModules, setfavoriteModules] = React.useState([] as Module[]);
    const [myModules, setMyModules] = React.useState([] as Module[]);
    const [recentModules, setRecentModules] = React.useState([] as Module[]);

    const fetchFavModules = async () => {
        try {
            const elem = await store.fetchBookMarkedModules();
            if (elem && elem.length > 0) {
                setfavoriteModules(elem)
            }
        }
        catch (error) {

        }

        setLoadingData(false);
    };

    const fetchMyModules = async () => {
        try {
            const elem = await await store.fetchMyModules();
            if (elem && elem.length > 0) {
                setMyModules(elem);
            }
        }
        catch (error) {

        }

        setLoadingData(false);
    };

    const fetchRecentModules = async () => {
        try {
            const elem = await store.fetchMyRecentModules();
            if (elem && elem.length > 0) {
                setRecentModules(elem)
            }
        }
        catch (error) {
        }

        setLoadingData(false);

    }
    const checkUserPresent = async () => {
        var success = false;
        try {
            const result = await store.checkUser(accounts[0].username);
            success = true;
        } catch (error) {
            if ((error as any).status === 401) {
                alert('Please log out and log back in again.');
            }
        }

        if (success) {
            fetchFavModules();
            fetchMyModules();
            fetchRecentModules();
        }
    }

    useEffect(() => {
        if(accounts){
            checkUserPresent()
        }
    }, [accounts])
    return loadingData ? (<div className="App">
        <Loading></Loading>
    </div>) : (
        <div className="dashboardApp fadeindownAnimation my-2 px-2">
            <div className="modulesContainer fadeindownAnimation">
                <MyModules myModules={myModules}></MyModules>
                <Favorite BookMarkedModules={favoriteModules}></Favorite>
                <Recent recentModules={recentModules}></Recent>
            </div>
        </div>
    )

});
