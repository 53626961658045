import { observer } from "mobx-react-lite";
import React, { RefObject, useContext, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import Quill from "quill";
import QuillEditor from "../../editing/QuillEditor";
import { ModuleListStoreImpContext } from "../../../store/moduleListStore";
import { ThematicQuestionModel } from "../../../store/moduleReviewApi";
import ConfirmationDialog from "../../general/ConfirmationDialog";
import quillHelper from "../../../helpers/DeltaHelper";
import SnackbarUtil, { SnackbarUtilProps, SnackbarUtilRef } from "../../utilities/snackBar/snackBar";

interface ThematicQuestionTabProps {
    id: number | null;
}

const ThematicQuestionTab: React.FC<ThematicQuestionTabProps> = observer(({ id }) => {
    const quillRef = useRef<Quill | null>();
    const snackbarUtilRef = useRef<SnackbarUtilRef>(null);
    const store = useContext(ModuleListStoreImpContext)
    const [thematicData, setThematicData] = useState<ThematicQuestionModel>();

    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const [isValid, setIsValid] = useState<boolean>(false);
    const [cancelDialogIsOpen, setCancelDialogIsOpen] = useState<boolean>(false);

    const fetchThematicQuestion = async (id: number) => {
        const thematic  = await store.fetchThematicQuestion(id);
        if (thematic) {
            setThematicData(thematic);
        }
    };

    const updateIsValid = () => {
        if (quillRef && quillRef.current) {
            setIsValid(quillRef.current.root.innerText.trim() !== '');
        }
    };

    useEffect(() => {
        if (id) {
            fetchThematicQuestion(id);
        }
    }, []);

    const cancelAction = () => {
        setCancelDialogIsOpen(true);
    };

    const cancelDialogResponse = (isCanceled: boolean) => {
        setCancelDialogIsOpen(false);
        if (isCanceled) {
            if (quillRef?.current)
                quillHelper.loadHtmlString(quillRef.current, "");
        } 
    }

    const saveAction = async () => {
        if (isValid && quillRef?.current) {
            const answerHtml = quillHelper.getHtmlString(quillRef.current);
            const response = await store.setThematicAnswer(id!, answerHtml);
            setSnackbarMessage(response.message!);
            snackbarUtilRef.current?.open();
        }
    };

    if (!thematicData) 
        return (<></>);
    else {
        if (thematicData.notSet) {
            return (
                <>
                    <p></p>
                    <p> Your department has not set a question. </p>
                </>
            );
        } else {
            const placeholderText = "Enter an answer to your department's thematic question here...";

            return (
                <>
                    <SnackbarUtil ref={snackbarUtilRef} message={snackbarMessage} />
                    <ConfirmationDialog dialogTitle="Are you sure?" dialogSubtitle="Any edits or text you have entered will be removed." okText="I am ok with this" cancelText="Don't do this" open={cancelDialogIsOpen} close={cancelDialogResponse} />
                    <p></p>
                    <p>Please answer the following thematic question that was set by your department:</p>
                    <blockquote> {thematicData.title} </blockquote>
                    <p></p>

                    <QuillEditor placeholder={placeholderText} ref={quillRef as unknown as RefObject<Quill>} readOnly={false} defaultValue={thematicData.answer} onSelectionChange={(_x,_y,_Z) => {}} onTextChange={(_x,_y,_Z) => updateIsValid()} />
                    <div className="flex justify-content-end py-2">
                        <Button variant="text" color="secondary" onClick={cancelAction}>Cancel</Button>
                        <Button disabled={!isValid} variant="outlined" color="primary" startIcon={<SaveIcon />} onClick={saveAction}>Save</Button>
                    </div>
                </>
            );
        }
    }
});

export default ThematicQuestionTab;