import Card from "@mui/material/Card";
import React, { useContext, useState } from "react";
import './resultCard.scss'
import InfoIcon from '@mui/icons-material/Info';
import TimelapseRoundedIcon from '@mui/icons-material/TimelapseRounded';
import { Button, Chip } from "@mui/material";
import { Module } from "../../../../../store/moduleReviewApi";
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ModuleListStoreImpContext } from "../../../../../store/moduleListStore";
import { useNavigate } from "react-router";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import titleWords from "../../../../utilities/titleWords";

interface resultCardPorps {
    module: Module | null
}

export const ResultCard = ({ module }: resultCardPorps) => {

    const store = useContext(ModuleListStoreImpContext)
    const [viewMore, setViewMore] = useState(true);
    const [favorite, setFavorite] = useState(module?.isBookmarked);
    const navigate = useNavigate();

    return (
        <div className="">
            <Card className="resultCard" >
                <div className="resultCardContainer">
                    <div className="flex">
                        <Card className="iconCard">
                            <div className="iconImage align-items-center flex flex-column justify-content-center">
                                <p className="iconText margin-remove-bottom text-white">{titleWords(module?.title as string)}</p>
                            </div>
                        </Card>
                        <div className="ml-2 width-100">
                            <div className="semContainer flex">
                                <div>
                                    <p className="cardSubtitle">{module?.code}</p>
                                    <p className="cardTitle">{module?.title}</p>

                                </div>
                                <div>
                                    {
                                        module?.reviewStatus === 2 ?
                                            <>
                                                <Tooltip title="Completed">
                                                    <CheckCircleIcon fontSize="small" className="completedIcon" />
                                                </Tooltip>
                                            </> :
                                            <>
                                                <Tooltip title="Action Required">
                                                    <InfoIcon fontSize="small" className="actionIcon" />
                                                </Tooltip>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="align-items-lg-end flex flex-row justify-content-between">
                            </div>
                        </div>
                    </div>
                    <div className="detailsContainer">
                        <div className="flex alignCenter">
                            <Tooltip title="Semester">
                                <div className="flex">
                                    <TimelapseRoundedIcon fontSize="medium" className="red-Color"></TimelapseRoundedIcon>
                                    <p className="teacherName">{module?.year}</p>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="teacherContainer">
                            <Button className="searchButton" variant="contained" size="small" endIcon={<ArrowForwardIcon fontSize="small" />} onClick={() => {
                                navigate('/moduleDetails', {
                                    state: {
                                        moduleId: module?.id,
                                    }
                                })
                            }}>View</Button>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}