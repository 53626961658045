import { Divider, Fab, IconButton } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Delete from "../deleteAction/deleteActions";
import EditIcon from '@mui/icons-material/Edit';
import DOMPurify from "dompurify";
import AddAction from "../addAction/addAction";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AvatarList from "../Avatar/avatar";
import './actionCard.scss'
import { ModuleListStoreImpContext } from "../../../../../store/moduleListStore";
import { Actions, Person } from "../../../../../store/moduleReviewApi";
import { useMsal } from "@azure/msal-react";

type PersonType = { displayName: string };
const ActionCard = (props: { question: number; occurence: number | null; action : Actions; store: any; roles: string[]; cardEvents: any }) => {
    const [editor, setEditor] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [person, setPerson] = useState<PersonType>()
    const [assigneList, setAssigneeList] = useState<PersonType[]>([])
    const store = useContext(ModuleListStoreImpContext)
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const accountUsername = (() => {
        if (account) {
            return account.username;
        } else {
            return "";
        }
    })();

    const changeevent = () => {
        setDeleted(true)
        props.cardEvents()
    }
    const editorEvent = () => {
        setEditor(false)
        props.cardEvents()
    }
    const fetchUserDetails = async (personProps:Person) => {
        if (personProps.email) {
            const person = await store.fetchUsers(personProps.email);
            return person as PersonType;
        }
        else{
            return Promise.resolve({ displayName: '' })
        }
    }

    useEffect(() => {
        fetchUserDetails(props.action.person!).then((res:PersonType) => {
            setPerson(res)
        })
        if(props.action.assignee) {
            let result:PersonType[] = []
            for(let elm of props.action.assignee){
                fetchUserDetails(elm).then((res:PersonType) => {
                    if(res){
                        result.push(res)
                    }
                })
            }
            setAssigneeList(result)
        }
    },[props])
    const purify = (x: string | undefined) => x ? DOMPurify.sanitize(x!) : "";
    return (
    <div className={deleted ? "fadedownAnimation" : "fadeindownAnimation"}>
        {!deleted && 
            <div className="">
                <div className="flex my-4">
                    <div className="align-items-center flex flex-column mr-3">
                        <AddCircleIcon className="mx-2 iconColorBlue" fontSize="small"></AddCircleIcon>
                        <div className="divider"></div>
                    </div>
                    <div className="w-100">
                        <div className="flex justify-content-between">
                            <div>
                                <p className="margin-remove-bottom programTitle">{person?.displayName}</p>
                                <p className="programSubTitle">{moment(new Date(props.action.lastUpdated!.toDateString())).format("LL")}</p>
                            </div>
                            {
                                props.action.person?.email == accountUsername && <div className="flex">
                                    <Fab className="actionButton"
                                        aria-label="edit"
                                        size="small"
                                        onClick={() => {
                                            setEditor(true)
                                        }}
                                    >
                                        <EditIcon fontSize="small" className="m-2 buttonIcon"></EditIcon>
                                    </Fab>
                                    <Delete deleteEvent={changeevent} action={props.action} store={props.store}></Delete>
                                </div>
                            }

                        </div>
                        {
                            editor ? <>
                                <div className="fadeindownAnimation">
                                    <AddAction 
                                        assignAction={false} 
                                        question={props.question} 
                                        mode={"edit"} 
                                        occurence={props.occurence} 
                                        editorComplete={editorEvent} 
                                        action={props.action} 
                                        store={props.store} 
                                        initialValue={props.action.description} 
                                        id={props.action.id} 
                                        refresh={() => {}} />
                                </div>
                            </> : <>
                                <div className="futureActionContainer">
                                    <span className="fadeindownAnimation mt-2 mx-2" 
                                        dangerouslySetInnerHTML={{ __html: purify(props.action.description) }} />
                                    {
                                        props.action.assignee && props.action.assignee.length > 0 &&
                                        <>
                                            <Divider variant="fullWidth" />
                                            <div className="align-items-center flex justify-content-between mx-2 my-2">
                                                <div className="align-items-center flex justify-content-between">
                                                    <p className="margin-remove-bottom programTitle widthAuto">Assignee:</p>
                                                    {
                                                        props.action.assignee.length === 0 ? <>
                                                            <p className=" margin-Zero font-weight-bold">No Assignee</p>
                                                        </> : <div className="ml-2">
                                                            <AvatarList userList={assigneList}></AvatarList>
                                                        </div>
                                                    }
                                                </div>
                                                <div>
                                                    <p className="margin-remove-bottom programSubTitle">Due:</p>
                                                    <p className="margin-remove-bottom programTitle">{moment(new Date(props.action.lastUpdated!.toDateString())).format("LL")}</p>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
    )
};

export default ActionCard;