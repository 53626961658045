import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ModuleListStoreImpContext, ModuleListStoreImpl } from "../../../store/moduleListStore";
import { AssessmentOutcome, Lumes } from "../../../store/moduleReviewApi";

interface OutcomesProps {
    id: number | null
}

const OutcomesTab: React.FC<OutcomesProps> = observer(({ id }) => {

    const store = useContext(ModuleListStoreImpContext)

    useEffect(() => {
        async function fetchOutcomes() {
            if (id) {
                await store.assessmentOutcomesFetch(id);
            }
        }
        fetchOutcomes()
    }, [])
    return (
        <>
            <div className="tab-pane p-4" id="outcomes">
                <p>
                    <strong>Assessment Outcomes</strong><br />
                    % students based on first attempts only, excluding students with outstanding assignments (change)
                </p>

                <table id="tableoutcomes" className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>{/* blank */}</th>
                            <th>%Weight</th>
                            <th>&lt;40.00</th>
                            <th>40.00-49.99</th>
                            <th>50.00-59.99</th>
                            <th>60.00-69.99</th>
                            <th>&gt;=70.00</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            store.assessmentOutcomes.map((x: AssessmentOutcome,index:any) => {
                                return (
                                    <tr key={index}>
                                        <td>{x.type}</td>
                                        <td>{x.weight}</td>
                                        <td>{x.fourtyLess}</td>
                                        <td>{x.fourtyPlus}</td>
                                        <td>{x.fiftyPlus}</td>
                                        <td>{x.sixtyPlus}</td>
                                        <td>{x.seventyPlus}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
})

export default OutcomesTab;