import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { ModuleListStoreImpContext } from "../../../store/moduleListStore";
import { EvasysResponse } from "../../../store/moduleReviewApi";

interface FeedbackProps {
    id: number | null
}

export const FeedbackTab: React.FC<FeedbackProps> = observer(
    ({ id }) => {

        const store = useContext(ModuleListStoreImpContext)
        const [evasysData, setEvasysData] = useState<EvasysResponse[]>([])

        const fetchEvasys = async (id: any) => {
            const evasys  = await store.evasysFetch(id);
            if (evasys) {
                setEvasysData(evasys);
            }
        };

        useEffect(() => {
            if (id) {
                fetchEvasys(id);
            }
        }, []);

        var satisfiedCount = evasysData.filter(x => x.satisfied).length;
        var totalCount = evasysData.length;
        var fraction = satisfiedCount/totalCount;
        var asPercentage = Math.round(fraction * 100);
        return (
            <>
                <br />
                {
                    evasysData.length > 0 &&
                    <p>
                        {satisfiedCount}/{totalCount} were satisfied ({asPercentage}%).
                    </p>
                }

                <br />
                <div className="tab-pane active" id="feedback">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Good</th>
                                <th>Improve</th>
                                <th>Satisfied</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                evasysData.length > 0 ? evasysData.map((elem: EvasysResponse, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td>{elem.good}</td>
                                            <td>{elem.improve}</td>
                                            <td>{(elem.satisfied ? "yes" : "no")}</td>
                                        </tr>
                                    )
                                }) : (
                                        <>
                                            <tr>
                                                <td><p className="noDataText">No Data Available</p></td>
                                            </tr>
                                   
                                        </>
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
)
