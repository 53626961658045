import React from "react";
import './home.scss';
import { ModuleList } from "./moduleList/modulelist";

export const Home = () => {
    return (
        <div className="appMain">
            <ModuleList />
        </div>
    );
}