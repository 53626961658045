import { Card, IconButton } from "@mui/material";
import React from "react";
import physics from '../../../assests/.jpg';
import InfoIcon from '@mui/icons-material/Info';
import "./moduleCard.scss"
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useNavigate } from "react-router";
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import titleWords from '../../utilities/titleWords';

const ModuleCard = (props: any) => {
    const navigate = useNavigate();

    return (

        <Card className="moduleCard p-2 m-2" onClick={() => {
            navigate('/moduleDetails', {
                state: {
                    moduleId: props.module.id,
                }
            })
        }}>
            <div className="h-100">
                <div className="pt-2">
                    <div className="align-items-center flex mb-2">
                        <div className="textIconContainer align-items-center flex flex-column justify-content-center mr-2">
                            <p className="iconText margin-remove-bottom text-white">{titleWords(props.module.title)}</p>

                        </div>

                        <div className="position-relative">
                            <p className="programTitle">{props.module.title}</p>
                        </div>
                    </div>


                    <div className="flex flex-row justify-content-between">
                        <p className="programSubTitle">{props.module.code}</p>
                        <p className="programSubTitle">{props.module.year}</p>

                    </div>


                    <div className="align-items-center flex flex-row justify-content-between">
                        <div className="flex p-1 infoContainer">
                            {
                                props.module!.reviewStatus! === 2 ? <>
                                    <Tooltip title="Completed">
                                        <div>
                                            <CheckCircleIcon fontSize="small" className="completedIcon" />
                                            <span className="programCellTitle">
                                                Completed
                                            </span>
                                        </div>

                                    </Tooltip>
                                </> :
                                    <>
                                        <Tooltip title="Action Required">
                                            <div>
                                                <InfoIcon fontSize="small" className="actionIcon" />
                                                <span className="programCellTitle">
                                                    Action Required
                                                </span>
                                            </div>
                                        </Tooltip>

                                    </>
                            }
                        </div>
                        <IconButton size="small" className="exploreButton">
                            <ArrowForwardRoundedIcon fontSize="small" />
                        </IconButton>

                    </div>

                </div>

            </div>
        </Card>

    )

}
export default ModuleCard;