import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ConfirmationDialogProps = {
    dialogTitle: string;
    dialogSubtitle: string;
    cancelText: string;
    okText: string;
    open: boolean;
    close: (result: boolean) => void;
};

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    return (
        <>
            <Dialog open={props.open}>
                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{props.dialogSubtitle}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.close(false)}>{props.cancelText}</Button>
                    <Button onClick={() => props.close(true)}>{props.okText}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmationDialog;