import { AppBar, Box, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, SpeedDial, SpeedDialAction, SpeedDialIcon, Toolbar } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FeedbackIcon from '@mui/icons-material/Feedback';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ChatIcon from '@mui/icons-material/Chat';
import MenuIcon from '@mui/icons-material/Menu';
import "./navbar.scss"
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ModuleListStoreImpContext } from "../../../../../store/moduleListStore";
import { Question } from "../../../../../store/moduleReviewApi";


const Navbar = (props: { questions : Question[], questionsToBeCompleted : number[], selectedTab: (index: number, question: Question) => void; }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const store = useContext(ModuleListStoreImpContext)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const Onclick = (index: number,elm:Question) => {
        setSelectedTab(index)
        props.selectedTab(index,elm)
        handleClose()
    }
    const SwitchIcons = (text: string, index: number) => {
        const className = selectedTab === index ? 'selectedIcon' : 'buttonIcon';
        switch (text) {
            case 'Changes':
                return <TrackChangesIcon className={className} fontSize="small" />
            case 'Delivery':
                return <InventoryIcon className={className} fontSize="small" />
            case 'Assessment':
                return <AssessmentIcon className={className} fontSize="small" />
            case 'Feedback Summary':
                return <FeedbackIcon className={className} fontSize="small" />
            case 'Feedback Reflections':
                return <RateReviewIcon className={className} fontSize="small" />
            case 'Comments':
                return <ChatIcon className={className} fontSize="small" />
            case 'Thematic question':
                return <ChatIcon className={className} fontSize="small" />
            case 'Actions for next session':
                return <ChatIcon className={className} fontSize="small" />
            case 'Peer discussion':
                return <ChatIcon className={className} fontSize="small" />
            default:
                return <ChatIcon className={className} fontSize="small" />

        }
    }

    const switchStatusIcon = (tobeCompleted: boolean) => {
        if (tobeCompleted) 
            return <InfoIcon fontSize="small" className="actionIcon" />;
        else 
            return <CheckCircleIcon fontSize="small" className="completedIcon" />;
    }
    useEffect(()=>{
        props.selectedTab(0,props.questions[0])
    },[])

    return (
        <div>
            <div className="dontdisplayforlargerScreen">
                <IconButton
                    size="small"
                    onClick={handleClick}
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
            </div>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {props.questions.map((elm:Question, index: number) => (
                    <MenuItem key={elm.id} className={selectedTab === index ? 'selectedList' : 'listitems'} onClick={() => {
                        Onclick(index,elm)
                    }}><div>
                            <div>
                                {
                                    SwitchIcons(elm.subtitle!, index)
                                }
                                <span className="p-2 font-weight-bold">{elm.subtitle!}</span>
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>
            <List className="noDisplaysmallerScreen">
                {props.questions.map((elm:Question, index: number) => (
                    <ListItem key={elm.id} className={selectedTab === index ? 'selectedList' : 'listitems'} disablePadding>
                        <ListItemButton onClick={() => {
                            Onclick(index,elm)
                        }} className={selectedTab === index ? 'selectedButton' : ''}>
                            <ListItemIcon>
                                {
                                    SwitchIcons(elm.subtitle!, index)
                                }
                            </ListItemIcon>
                            <ListItemText primary={elm.subtitle!} />
                            <ListItemIcon className="flex justify-content-end">
                                {switchStatusIcon(props.questionsToBeCompleted.includes(elm.id))}
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    )

}
export default Navbar;
