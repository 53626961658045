import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { RedditTextField } from "../styles";

const DatePickerUtil = (props:any) => {
    const [dateValue, setDateValue] = useState<any>(null)
    const handleChange = (newValue: any) => {
        setDateValue(newValue);
    };
    useEffect(() => {
        props.assignDate(dateValue)
    },[dateValue])
    return (
        <DatePicker
            value={dateValue}
            onChange={handleChange}
            renderInput={(params) => (
                <RedditTextField
                    {...params}
                    id="reddit-input"
                    variant="filled"
                    size="small"
                    placeholder="Due..."
                />

            )}
        />
    )

}
export default DatePickerUtil