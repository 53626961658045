import React, { useContext } from "react";
import ModuleCard from "../moduleCard/moduleCard";
import "./favorite.scss"
import physics from '../../../assests/physics.jpg';



const Favorite = (props: any) => {
    return (
        <div className="favoriteContainer">
            {
                props.BookMarkedModules.length === 0 ? <>
                    {/* <div className="noDataContainer flex justify-content-center noDataContainer p-2">
                        <p className="noDataText">No Data Available</p>
                    </div> */}
                </> :
                    <>
                        <p className="title">Favorite Modules</p>
                        <div className="flex  px-3 w-100 custom-scorll-carousel carousel">
                            {
                                props.BookMarkedModules.map((elm: any, index: any) => {
                                    return (
                                        <ModuleCard key={index} image={physics} module={elm}></ModuleCard>
                                    )

                                })
                            }
                        </div>
                    </>
            }

        </div>
    )
}
export default Favorite;