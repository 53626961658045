import './ActionsTab.scss';
import { Box, Button, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ModuleListStoreImpContext } from "../../../../store/moduleListStore";
import { Actions, Question } from "../../../../store/moduleReviewApi";
import AddAction from "./addAction/addAction";
import Loading from "../../../utilities/loading/loading";
import ActionCard from "./actionCard/actionCard";
import Navbar from "./navbar/navbar";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import ActionHistories from "./actionsHistory/actionHistory";

interface ActionProps {
    id: number | null
}

const ActionsTab: React.FC<ActionProps> = observer(({ id }) => {

    const store = useContext(ModuleListStoreImpContext)
    const [editorVisible, setEditorVisible] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [actions, setActions] = useState(store.actionsData);
    const [roles, setRoles] = useState<string[]>([]);
    const [lastIndex, setLastIndex] = useState(4);
    const [questions, SetQuestions] = useState<Question[]>([]);
    const [currentQuestion, SetCurrentQuestion] = useState<Question>();
    const changeevent = () => {
        fetchActions()
    }
    async function setEditorVisibility() {
        const userIsConvenorOfModule = await store.userIsConvenorOfModule(id as number);
        setEditorVisible(userIsConvenorOfModule);
    }
    async function fetchActions() {
        if (id) {
            const questionIds = await store.fetchQuestionIds();
            await store.actionsFetch(id);
            const actionstobeCompleted = [];
            for (let questionId of questionIds) {
                if (store.actionsData.filter(act => act.question == questionId).length === 0) {
                    actionstobeCompleted.push(questionId)
                }
            }
            store.setActions(actionstobeCompleted)
            setActions([...store.actionsData].sort(((a, b) => b.lastUpdated!.getTime() - a.lastUpdated!.getTime())))
        }
    }

    function hideEditor() {
        setEditorVisible(false);
    }

    
    useEffect(() => {
        fetchActions();
        setEditorVisibility();
        if(store.roles){
            let temp : string[] = []
            store.roles.map(elm => {
                temp.push(elm)
            })
            setRoles(temp)
        }
        store.fetchQuestions().then(result => {
            if (result) {
                SetQuestions(result)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
    }, []);

    const viewMoreClick = () => {
        setLastIndex(lastIndex + 4);
    };

    const selectedTabsActions = actions.filter(i => i.question === currentQuestion?.id); 

    return (
        !loading ? (
            <div className="w-100 actionBoxContainer">
                <Box sx={{ display: 'flex' }} >
                    <Navbar questionsToBeCompleted={store.questionsTobeCompleted} questions={questions} selectedTab={(index: number,question:Question) => {
                        setSelectedTab(index)
                        SetCurrentQuestion(question)
                    }}></Navbar>
                    <Divider orientation="vertical" variant="middle" flexItem />
                </Box>
                <div className="w-100">
                    <Box
                        component="main"
                        className="mainContainer"
                        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                    >
                        <div className="flex justify-content-between">
                            <div>
                                <p className="margin-Zero title">{currentQuestion?.title}</p>
                                <p className="detailGrayTitle mt-2">{currentQuestion?.description}</p>
                            </div>
                            <ActionHistories moduleId={id as number} selectedTab={selectedTab} />
                        </div>
                        <Divider/>
                        {
                            selectedTabsActions.length === 0 ? 
                                <>
                                    <div className="align-items-md-center flex futureActionContainer justify-content-center p-4 my-4">
                                        <p className="grayColor margin-remove-bottom">No actions entered</p>
                                    </div>
                                </> 
                                :
                                <>
                                    {selectedTabsActions.slice(0, lastIndex).map((x: Actions, index: number) => {
                                        return (
                                                <ActionCard question={x.question} occurence={id} key={index} action={x} roles={roles} store={store} cardEvents={changeevent}></ActionCard>
                                        )
                                    })}
                                    {
                                        (selectedTabsActions.length > 4 && lastIndex <= selectedTabsActions.length) && 
                                            <div>
                                                <div className="d-block d-md-none">
                                                    <div className="flex justify-content-center my-4 w-100">
                                                        <Button variant="outlined" size="small" onClick={viewMoreClick}>View 4 more actions</Button>
                                                    </div>
                                                </div>
                                                <div className="d-none d-md-block">
                                                    <div className="mx-5 p-4" onClick={viewMoreClick}>
                                                        <Divider>
                                                            <div className="flex">
                                                                <ArrowDropDownCircleIcon className="mx-2 iconColorBlue" fontSize="small"></ArrowDropDownCircleIcon>
                                                                <p className="margin-remove-bottom text-primary cursor-pointer" tabIndex={0}>View 4 more actions</p>
                                                            </div>
                                                        </Divider>
                                                    </div>
                                                </div>
                                        </div>
                                    }
                                </>
                        }
                        {
                            editorVisible && currentQuestion && <>
                                <AddAction question={currentQuestion!.id} assignAction={selectedTab + 1 === 8}  occurence={id} refresh={changeevent} store={store} mode="add" action={null} editorComplete={hideEditor} ></AddAction></>
                        }
                    </Box>
                </div>
            </div>
        ) : (<div className="App">
            <Loading></Loading >
        </div >)

    )

})
export default ActionsTab;
