import Quill, { Delta } from "quill/core";

class QuillHelper {
    getHtmlString = (q: Quill) => q.root.innerHTML;
    loadHtmlString = (q: Quill, htmlString: string) => {
        const delta = q.clipboard.convert({ html: htmlString });
        q.setContents(delta);

    }
}

const quillHelper = new QuillHelper();

export default quillHelper;