import * as React from 'react';
import './navbar.scss'
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import uniLogo from '../../../../ClientApp/src/assests/uniLogo.png';
import profilePic from '../../../../ClientApp/src/assests/profilePic.svg';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button';
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { useNavigate } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Avatar, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

// Official MUI breakpoint technique https://mui.com/material-ui/customization/breakpoints/
export const Navbar = () => {

    const Small = styled('div')(({ theme }) => ({
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        }
    }));

    const NotSmall = styled('div')(({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }));
    
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const account = accounts[0];
    const accountUsername = (() => {
        if (account) {
            return account.username;
        } else {
            return "";
        }
    })();
    
    const logOutText = `Sign out (${accountUsername})`;
    const [drawerState, setDrawerState] = React.useState(false);
    const toggleDrawer = 
        (open: boolean) => 
        (event: React.KeyboardEvent | React.MouseEvent) => {
              if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                  (event as React.KeyboardEvent).key === 'Shift')
              ) {
                return;
              }

              setDrawerState(open);
        };

    const drawerContent = () => (
        <Box
          sx={{ width: 'auto' }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >

            <Small>
                <List>
                    <ListItem key='Home' disablePadding>
                        <ListItemButton
                            onClick={() => {navigate('/dashboard')}} 
                        >
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary='Home' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key='Search' disablePadding>
                        <ListItemButton
                            onClick={() => {navigate('/search')}} 
                        >
                            <ListItemIcon>
                                <SearchIcon />
                            </ListItemIcon>
                            <ListItemText primary='Search' />
                        </ListItemButton>
                    </ListItem>
                </List>
                <Divider />
            </Small>
          <List>
              <ListItem key='Sign out' disablePadding>
                <ListItemButton onClick={() => handleLogout(instance)}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={logOutText} />
                </ListItemButton>
              </ListItem>
          </List>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar className='navBar'>
                    <div className='logoContainer'>
                        <img src={uniLogo} className='logoPic mr-5' />
                        <NotSmall>
                            <Button size='small' 
                                    className='mr-5 topBarButton p-2' 
                                    onClick={() => {navigate('/dashboard')}} 
                                    startIcon={<HomeIcon fontSize='small' />}>
                                Home
                            </Button>
                            <Button size='small' 
                                    className='mr-5 topBarButton p-2' 
                                    onClick={() => {navigate('/search')}} 
                                    startIcon={<SearchIcon fontSize='small' />}>
                                Search
                            </Button>
                        </NotSmall>
                    </div>
                    <div className='flex'>
                        <IconButton
                            onClick={toggleDrawer(true)}
                            size="small"
                            sx={{ ml: 2 }}
                        >
                            <Avatar alt="accountImage" className='profilePic' src={profilePic} />
                        </IconButton>
                        <NotSmall>
                            <div className='p-2'>
                                <UnauthenticatedTemplate>
                                    <SignInButton />
                                </UnauthenticatedTemplate>
                            </div>
                        </NotSmall>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
              anchor={'top'}
              open={drawerState}
              onClose={toggleDrawer(false)}
            >
              {drawerContent()}
            </Drawer>

        </Box>
    );

}
