import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import React from "react";
import { useNavigate } from "react-router-dom";
import error from '../../../assests/error.svg'

export const ErrorPage = () => {
    const navigate = useNavigate()
    return (
        <div className="align-items-center flex flex-column">
            <img src={error} className='p-3 width-40' />
            <div className="searchContainerBox">
                <div className="align-items-lg-center flex flex-column">
                    <p className="searchtitle">404</p>
                    <p className="margin-remove-bottom searchsubtitle">Oh no,Something went wrong! </p>
                    <p className="searchsubtitle">We Can't seem to find the page that you're looking for</p>
                </div>
            </div>
            <Button
                variant="contained" size="large" className="searchButton"
                onClick={() => navigate('/dashboard')}
            >
                <span className="text-captilize">
                    Back to Home
                </span>
            </Button>
        </div>
    )
}