import * as React from 'react';
import { Home } from './components/home/home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ModuleListStoreImpContext, ModuleListStoreImpl } from "./store/moduleListStore";
import './custom.css'
import './mixin.scss'
import { Navbar } from './components/navbar/navbar';
import { SearchHome } from './components/home/search/search';
import { ErrorPage } from './components/utilities/errorPage/error';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Dashboard } from './components/dashboard/dashboard';
import { useEffect, useState } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { storedAuthConfig } from './AuthConfigProvider';

export default () => {
    const { instance } = useMsal();
    const store = new ModuleListStoreImpl(instance, storedAuthConfig.loginRequest);

   
    useEffect(() => {
        async function tokenCheck() {
            await store.getToken();
        }
        tokenCheck()
    }, [])

    return (
        <AuthenticatedTemplate>
            <ModuleListStoreImpContext.Provider value={store}>
                <div>
                    <Navbar></Navbar>
                    <Routes>
                        <Route path="/Error" element={<ErrorPage />}></Route>
                        <Route path="/search" element={<SearchHome />}></Route>
                        <Route path="/moduleDetails" element={<Home />}></Route>
                        <Route path="/dashboard" element={<Dashboard />}></Route>
                        <Route path="/" element={<Dashboard />}></Route>
                    </Routes>
                </div>
            </ModuleListStoreImpContext.Provider>
        </AuthenticatedTemplate>

    )
};
