import { Box, Tabs, Tab } from "@mui/material";
import React, { useContext } from "react";
import './moduleDetail.scss'
import { FeedbackTab } from './FeedbackTab';
import OutcomesTab from './OutcomesTab';
import Typography from '@mui/material/Typography';
import { Module } from "../../../store/moduleReviewApi";
import { observer } from "mobx-react";
import ActionsTab from "./actions/ActionsTab";
import ThematicQuestionTab from "./ThematicQuestionTab";

interface ModuleDetailProps {
    module: Module | null
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const ModuleDetail: React.FC<ModuleDetailProps> = observer(
    ({ module }) => {

        const [tabIndex, setTabIndex] = React.useState(module!.reviewStatus === 1 ? 3 : 0);

        const handleTabChange = (event: React.SyntheticEvent, tabInd: number) => {
            setTabIndex(tabInd);
        };

        return (
            <div>
                <Box className="mainDetailscontainer">
                    <Box sx={{ width: '100% ' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                            <Tabs value={tabIndex} onChange={handleTabChange}  variant="scrollable" scrollButtons
                                allowScrollButtonsMobile aria-label="basic tabs example" >
                                <Tab label="Feedback" className="MuiTabs-indicatorSpan" {...a11yProps(0)} />
                                <Tab label="Assessment Outcomes" {...a11yProps(1)} />
                                <Tab label="Review" {...a11yProps(2)} />
                                <Tab label="Thematic Question" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={tabIndex} index={0}>
                            <FeedbackTab id={module!.id} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <OutcomesTab id={module!.id} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <ActionsTab id={module!.id} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <ThematicQuestionTab id={module!.id} />
                        </TabPanel>
                    </Box>
                </Box>
            </div>
        );
    }
)


