import React, { useContext, useEffect, useState } from "react";
import { ModuleListStoreImpContext } from "../../../../../store/moduleListStore";
import { ActionHistory } from "../../../../../store/moduleReviewApi";
import { Box, Card, Button, InputBase, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import "./actionHistory.scss"
import DOMPurify from "dompurify";
import Divider from '@mui/material/Divider';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import Loading from "../../../../utilities/loading/loading";
import moment from "moment";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import searchIcon from "../../../../../assests/search.png"
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { observer } from "mobx-react";

const ActionHistoryItem = (props: { action: ActionHistory }) => {
    const Icon = (process: string | undefined) => {
        switch (process) {
            case 'CREATE':
                return <AddCircleIcon className="mx-2 iconColorBlue" fontSize="small"></AddCircleIcon>
            case 'UPDATE':
                return <EditRoundedIcon className="mx-2 iconColorBlue" fontSize="small"></EditRoundedIcon>
            case 'DELETE':
                return <DeleteForeverRoundedIcon className="mx-2 iconColorBlue" fontSize="small"></DeleteForeverRoundedIcon>

        }
    };

    const purify = (x: string | undefined) => x ? DOMPurify.sanitize(x!) : "";
    const action = props.action;
    return (
        <div className="flex">
            <div className="flex flex-column justify-content-center alignCenter">
                {Icon(action.process)}
                <div className="align-self-md-center flex solid"></div>
            </div>
            <div className="flex flex-column w-100">
                <div className="flex actionTitlesContainer">
                    <div className="flex">

                        <p className="margin-remove-bottom">
                            <span className="grayTitle mr-1">
                                Action
                            </span>
                            <span className="grayTitle mr-1">
                                was
                            </span>
                            <span className={
                                action.process === 'CREATE' ?
                                    "text-lowercase mr-1 actionHistoryTitle greenTitle" : action.process === 'UPDATE' ? "text-lowercase mr-1 actionHistoryTitle" : "text-lowercase mr-1 actionHistoryTitle redTitle"}>
                                {action.process}d
                            </span>
                            <span className="mr-1 grayTitle">
                                by
                            </span>
                            <span className="actionHistoryTitle font-weight-bold">
                                {action.person?.firstname}
                            </span>
                        </p>
                    </div>
                    <div className="align-items-center flex mx-2 dateHistoryContainer">
                        <span className="dot mr-2 display-none-smallerScreen"></span>
                        <p className="margin-remove-bottom programSubTitle">{moment(new Date(action.created!.toDateString())).format("LL")}</p>

                    </div>

                </div>

                <Card className="actionHistoryCard ml-2 mt-2">
                    <Typography component={'span'} variant={'body2'} className={action.process === 'DELETE' ? "text-line-through margin-remove-bottom grayTitle" : "margin-remove-bottom"} dangerouslySetInnerHTML={{ __html: purify(action.description) }}>
                    </Typography>
                </Card>
            </div>
        </div>
    );
}

const LoadingSection = () => {
    return (
        <div className="App">
            <Loading></Loading>
        </div>
    );
}
interface ActionHistoriesProps {
    selectedTab: number; 
    moduleId: number;
}
const ActionHistories : React.FC<ActionHistoriesProps> = observer((props: ActionHistoriesProps) => {
    const store = useContext(ModuleListStoreImpContext);
    const [actionHistoryData, setActionHistoryData] = useState<ActionHistory[]>([]);
    const [filteredActionHistory, setFilteredActionHistory] = useState<ActionHistory[]>([]);
    const [open, setOpen] = React.useState(false);
    const [lastIndex, setLastIndex] = useState(4);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [displayActionHistoryList, setdisplayActionHistoryList] = useState<ActionHistory[]>([]);
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value.length === 0 ? setErrorMessage('Please enter a value.') : setErrorMessage('')
        setSearchTerm(event.target.value);
        search(event.target.value);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const fetchHistory = async (questionId: number, moduleId: number) => {
        setLoading(true);
        setLastIndex(4);
        const actionHistory = await store.fetchActionHistory(questionId, moduleId);
        const ah = actionHistory.sort((a, b) => b.created!.getTime() - a.created!.getTime());

        setActionHistoryData(ah);
        setFilteredActionHistory(ah);
        setdisplayActionHistoryList(ah.slice(0, lastIndex));
        setLoading(false);
    }

    useEffect(() => {
        fetchHistory(props.selectedTab + 1, props.moduleId);
    }, [props.selectedTab, props.moduleId, store.actionsData]);

    useEffect(() => {
        setdisplayActionHistoryList(filteredActionHistory.slice(0, lastIndex));
    }, [lastIndex, searchTerm]);

    const search = (value: string) => {
        if (value.length > 0) {
            setFilteredActionHistory(actionHistoryData.filter((item) => {
                return item.description!.toLowerCase().includes(value.toLowerCase()) || item.person?.firstname?.toLowerCase().includes(value.toLowerCase()) || item.person?.surname?.toLowerCase().includes(value.toLowerCase());
            }))
        }
        else {
            setFilteredActionHistory(actionHistoryData);
        }
    };
    return (
        <>
            <div>
                <Button variant="contained" onClick={handleClickOpen}>Activity Log</Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-dialog-title"
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle id="responsive-dialog-title" className="flex justify-content-between">
                    Activity Log
                </DialogTitle>
                <DialogContent>
                        <div className="actionHistoryBoxContainer">
                            <div className="flex flex-column fadeindownAnimation w-100">
                                <Box
                                    component="main"
                                    className="mainContainer p-0"
                                    sx={{ flexGrow: 1, bgcolor: 'background.default' }}
                                >
                                    {
                                        !loading ?
                                            <>
                                                <div className="flex justify-content-end my-3">
                                                    <Paper
                                                        className="searchCardContainerHistory w-100"
                                                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <img src={searchIcon} className='searchIcon m-1' />
                                                        <InputBase
                                                            sx={{ ml: 1, flex: 1 }}
                                                            placeholder="Search Actions"
                                                            error={errorMessage.length !== 0}
                                                            value={searchTerm}
                                                            onChange={handleSearchTermChange}
                                                            inputProps={{ 'aria-label': 'search Actions' }}
                                                        />
                                                        <IconButton sx={{ p: '10px' }} aria-label="close" onClick={() => {
                                                            setSearchTerm("")
                                                            search("")
                                                        }}>
                                                            <CloseIcon fontSize="small" className="buttonIcon" />
                                                        </IconButton>
                                                    </Paper>
                                                </div>

                                                {
                                                    displayActionHistoryList.length === 0 
                                                    ? <>
                                                        <div className="align-items-md-center flex futureActionContainer justify-content-center p-4">
                                                            <p className="grayColor margin-remove-bottom">Nothing found</p>
                                                        </div>
                                                      </>
                                                    : displayActionHistoryList.map((item, index) => {
                                                            return (
                                                                <div key={index} className="fadeindownAnimation my-3">
                                                                    <ActionHistoryItem action={item} />
                                                                </div>
                                                            )
                                                      })
                                                }
                                                {
                                                    (filteredActionHistory.length > 4 && lastIndex <= filteredActionHistory.length) && 
                                                    <div className="w-100">
                                                        <div className="display-none-largeScreen flex justify-content-center my-4 w-100">
                                                            <Button variant="outlined" size="small" onClick={() => {
                                                                setLastIndex(lastIndex + 4)
                                                            }}>View more...</Button>
                                                        </div>
                                                        <div className="mx-5 p-4 display-none-smallerScreen" onClick={() => {
                                                            setLastIndex(lastIndex + 4)
                                                        }}>
                                                            <Divider>
                                                                <div className="flex">
                                                                    <ArrowDropDownCircleIcon className="mx-2 iconColorBlue" fontSize="small"></ArrowDropDownCircleIcon>
                                                                    <p className="margin-remove-bottom text-primary cursor-pointer" tabIndex={0}>View 4 more...</p>
                                                                </div>
                                                            </Divider>
                                                        </div>
                                                    </div>
                                                }
                                            </> :
                                            <LoadingSection />
                                    }
                                </Box>
                            </div>
                        </div>
                   
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
});
export default ActionHistories;