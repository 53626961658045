import React, { useContext, useEffect, useState } from "react";
import { Module, Person } from "../../../store/moduleReviewApi";
import { observer } from "mobx-react";
import { Avatar, Badge, Card, Chip, Divider, IconButton, styled } from "@mui/material";
import "./innerModuleDetail.scss"
import SchoolIcon from '@mui/icons-material/School';
import profilePic from '../../../assests/profilePic.svg'
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import Button from '@mui/material/Button';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import moment from "moment";
import { ModuleListStoreImpContext } from "../../../store/moduleListStore";
import MarkAsComplete from "./actions/markAsComplete/markAsComplete";
import ShareAction from "./actions/shareModule/shareModule";
import { LoadingButton } from "@mui/lab";
import AvatarList from "./actions/Avatar/avatar";
import AdminAddition from "./actions/addAdmin/admin";
interface InnerModuleDetailProps {
    module: Module | null
    store: any,
    role:string[]
}


const InnerModuleDetail: React.FC<InnerModuleDetailProps> = observer(
    ({ module,role }) => {
        const store = useContext(ModuleListStoreImpContext)
        const [loading, setLoading] = useState(false)
        const [convenors, setConvenors] = useState<Person[] | any>([])
        const [sharedPeople, setSharedPeople] = useState<Person[] |any>([])
        const [toBeCompletedList, setToBeCompletedList] = useState<number[]>(store.questionsTobeCompleted)

        const convernotActionCompleted = (newPerson: Person[]) => {
            setConvenors([...convenors, ...newPerson])
            fetchConvenors()
        }

        const fetchConvenors = async () => {
            if (module?.id) {
                const convenorsArr = await store.fetchConvenors(module.id)
                fetchUserDetails(convenorsArr).then(res => {
                    let resolvedValue = res.map(elm => {
                        if(elm.status === 'fulfilled'){
                            return elm.value
                        }
                    })
                    setConvenors(resolvedValue)
                })
                
            }
        }

        const fetchSharedPeople = async () => {
            if (module?.id) {
                const shared = await store.fetchSharedPeople(module.id)
                fetchUserDetails(shared).then(res => {
                    let resolvedValue = res.map(elm => {
                        if(elm.status === 'fulfilled'){
                            return elm.value
                        }
                    })
                })
            }
        }
        const fetchUserDetails = async (userList: Person[]) => {
            return Promise.allSettled(
                userList.map(async (elm) => {
                    if(elm.email){
                        return await store.fetchUsers(elm.email).then(res => {
                            let person = {
                                id: elm.id,
                                email: elm.email,
                                firstname: res.givenName,
                                surname: res.surname,
                                currentPerson: elm.currentPerson
                            }
                            return person
                        })
                    }
                    
                })

            )
        }
        useEffect(() => {
            fetchConvenors()
            fetchSharedPeople()
        }, [])
        useEffect(() => {
            setToBeCompletedList(store.questionsTobeCompleted)
        }, [store.questionsTobeCompleted])

        return (
            <Card className="border-radius-blue moduleDetailsContainer">
                <div className="moduleOwnerDetails">
                    <div className="align-items-center flex mx-2 mb-3">
                        <SchoolIcon fontSize="small" className="mr-1 buttonIcon"></SchoolIcon>
                        <h5 className="detailSubTitle margin-Zero font-weight-bold">{module?.code}</h5>
                    </div>
                    <div className="m-2">
                        <h2 className="detailsTitle">{module?.title}</h2>
                    </div>
                    <div className="mt-4">
                        <div className="flex justify-content-between m-2 p-2 align-items-center">
                            <div className="flex">
                                <LocalOfferIcon fontSize="small" className="buttonIcon mr-2"></LocalOfferIcon>
                                <p className="detailGrayTitle margin-Zero">Status</p>
                            </div>
                            <div className="">
                                {
                                    module?.reviewStatus === 2 ?
                                        <Chip className="greenChip" label="Completed" />
                                        :
                                        <Chip className="backgroundYellowChip" label="In Progress" />
                                }

                            </div>
                        </div>
                        <div className="flex justify-content-between m-2 p-2 align-items-center">
                            <div className="flex">
                                <PeopleRoundedIcon fontSize="small" className="buttonIcon mr-2"></PeopleRoundedIcon>
                                <p className="detailGrayTitle margin-Zero">Assignee</p>
                            </div>
                            <div className="flex flex-wrap">
                                {
                                    convenors.length === 0 ? <>
                                        <p className=" margin-Zero font-weight-bold">No Assignee</p>
                                    </> : <>
                                        <AvatarList userList={convenors}></AvatarList>
                                    </>

                                }
                            </div>
                            {
                                role.indexOf('Primary Convenor') !== -1 && <>
                                    <Divider orientation="vertical" flexItem />
                                    <AdminAddition convenors={convenors} convenorAction={convernotActionCompleted} id={module?.id}></AdminAddition>
                                </>
                            }

                        </div>
                        <div className="flex justify-content-between m-2 p-2 align-items-center">
                            <div className="flex">
                                <HistoryRoundedIcon fontSize="small" className="buttonIcon mr-2"></HistoryRoundedIcon>
                                <p className="detailGrayTitle margin-Zero">Last Updated</p>
                            </div>
                            <div className="">
                                <p className="margin-Zero font-weight-bold">{module!.lastUpdated &&  moment(new Date(module!.lastUpdated!.toDateString())).format("LL")}</p>
                            </div>
                        </div>

                    </div>

                </div>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div className="flex flex-column justify-content-between w-100">
                    <div className="p-3">
                        <div className="flex justify-content-between">
                            <p className="title">To Do</p>
                            {/* <ShareAction currentUsers={sharedPeople} id={module?.id}></ShareAction> */}
                        </div>
                        {
                            toBeCompletedList.length === 0 ? <>
                                <Card className="p-3 todoContainer ml-3 mt-3">
                                    <p className="font-weight-bold margin-Zero">No Actions required</p>
                                </Card>
                            </> : <>
                                <Card className="p-3 todoContainer ml-3 mt-3">
                                    <p className="font-weight-bold margin-Zero">Please complete all the sections in the Review tab. Once you have completed all these sections, mark it as completed.</p>
                                    {/* <span className="font-weight-bold margin-Zero">Please complete sections </span>
                                    {
                                        toBeCompletedList.map((elem: number) => {
                                            return (
                                                store.actionsTobeCompleted.indexOf(elem) === toBeCompletedList.length - 1 ? <span key={elem}>
                                                    <span className="font-weight-bold margin-Zero">and {elem} </span>
                                                </span> : toBeCompletedList.indexOf(elem) === toBeCompletedList.length - 2 ? <span key={elem}>
                                                    <span key={elem} className="font-weight-bold margin-Zero">{elem} </span>
                                                </span> : <span key={elem}>
                                                    <span key={elem} className="font-weight-bold margin-Zero">{elem},</span>
                                                </span>
                                            )
                                        })
                                    }
                                    <span className="font-weight-bold margin-Zero">in the future Actions tab.Once these sections have been completed you will be able to mark this review as complete.</span> */}
                                </Card>

                            </>
                        }

                    </div>

                    <div>
                        <Divider variant="middle" />
                        <div className="flex justify-content-end m-3">
                            <LoadingButton
                                loading={loading}
                                loadingPosition="start"
                                className="mr-3"
                                startIcon={<CloudDownloadRoundedIcon />}
                                variant="outlined" onClick={() => {
                                    setLoading(true)
                                    module?.id && store.downloadPdfAction(module.id).then(res => {
                                        let elm = document.createElement('a') 
                                        elm.href = URL.createObjectURL(res!.data)
                                        window.open(elm.href)
                                        setLoading(false)


                                    })
                                }}
                            >
                                Download
                            </LoadingButton>
                            <MarkAsComplete id={module?.id} store={store} status={module?.reviewStatus}></MarkAsComplete>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }
)

export default InnerModuleDetail;