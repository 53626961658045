import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import React, { useContext,  useRef,  useState } from "react";
import searchIcon from "../../../assests/search.png"
import './search.scss'
import { ModuleListStoreImpContext } from "../../../store/moduleListStore";
import { Module } from "../../../store/moduleReviewApi";
import { observer } from "mobx-react";
import { Results } from "./results/results";
import { Box, CircularProgress } from "@mui/material";
import searchBackground from '../../../assests/programming.svg'
import SnackBarUtil, { SnackbarUtilRef } from "../../utilities/snackBar/snackBar";

export const SearchHome = observer(() => {
    const store = useContext(ModuleListStoreImpContext)
    const snackbarRef = useRef<SnackbarUtilRef>(null);

    const [searchTerm, setSearchTerm] = React.useState('');
    const [isloading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.value.length === 0 ? setErrorMessage('Please enter a value.') : setErrorMessage('')
        setSearchTerm(event.target.value);
    };


    const [modules, setModules] = React.useState([] as Module[]);

    const search = async () => {
        if (searchTerm.length > 0) {
            setIsLoading(true)
            await store.searchModules(searchTerm).catch(err => {
                setIsLoading(false)
            }).then((elem) => {
                setIsLoading(false)
                if(elem && elem.length > 0) {
                    setModules(elem);
                }
                else{
                    snackbarRef.current?.open();
                }
            });
        }

    };

    // the technique to find the type of `e` is to first use the 
    // (e) => { } syntax. and then you can hover over e and you will
    // get the type.
    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            search();
        }
    };


    return (
        <div className="appSearchMain">
            <div className={modules.length > 0 ? "searchhome flex" : "searchhome flex"}>
                <div className="searchContainerBox p-2">
                    <Card className="searchContainerTextBox">

                        <Card className="searchCard">
                            <img src={searchIcon} className='searchIcon ' />
                            <TextField 
                                fullWidth 
                                id="fullWidth" 
                                size="small" 
                                error={errorMessage.length !== 0} 
                                variant="standard" 
                                className="searchTextBox" 
                                value={searchTerm} 
                                helperText={errorMessage} 
                                onChange={handleSearchTermChange} 
                                placeholder={"module code or module name"}
                                onKeyUpCapture={(e) => handleKeyUp(e)}
                                />
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant="contained" size="small" className="searchButton"
                                    onClick={() => search()}
                                >
                                    {isloading ? (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: "#ffff",
                                            }}
                                        />
                                    ) : <span>Search</span>}

                                </Button>

                            </Box>

                        </Card>
                    </Card>

                </div>
            </div>
            <div>
                {
                    modules.length > 0 &&
                    <Results modules={modules}></Results>
                }
            </div>
            <SnackBarUtil ref={snackbarRef} message ="0 Results found.Please try again."></SnackBarUtil>
            

        </div>
    )
})
