import { Autocomplete, Chip, } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ModuleListStoreImpContext } from "../../../store/moduleListStore";
import { RedditTextField } from "../styles";
import "./peoplePicker.scss"



const PeoplePicker = (props: any) => {
    const store = useContext(ModuleListStoreImpContext)
    const [searchUserList, setSearchUserList] = React.useState<any[]>([]);
    const [selectedUserList, setSelectedUserList] = useState<any[]>([])
    const [selectedUserListDetails, setselectedUserListDetails] = useState<any[]>([])
    const [searchValue, SetSeacrhValue] = useState("")

    const debouncer = (fn: any,context: any) => {
        let timer: NodeJS.Timeout
        return function(...args: any){
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                fn.call(context,...args)
            },2000)
        }
    }
    const search = async (e: any) => {
        SetSeacrhValue(e.target.value)
        setSearchUserList([])
        if (e.target.value) {
            await store.searchPeoples(e.target.value).then(result => {
                if (result.length > 0) {
                    setSearchUserList(result.filter(elm => !selectedUserList.some(person => person.id === elm.id) ))
                }
            })
        }

    }
    // const searchDebounced = debouncer(search,this)
    useEffect(() => {
        props.selectedUsers(selectedUserList)
    }, [selectedUserList])

    return (
        <div>
            <Autocomplete
                id="combo-box-demo"
                multiple
                value={selectedUserList}
                options={searchUserList}
                onInputChange={(e) => search(e)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, newValue) => {
                    if (props.currentUsers.some((elm: any) => elm.id === newValue[0].id)) {
                        // setsnackBarOpen(true)
                    }
                    else {
                        setSelectedUserList([...newValue])
                    }
                }}
                onClose={() => {

                }}
                getOptionLabel={(option) => `${option.email}`}
                renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: any, index: number) => (
                        <Chip label={`${option.email}`}   {...getTagProps({ index })} className="chips" />
                    ))
                }
                renderInput={(params) => (
                    <RedditTextField
                        label=""
                        {...params}
                        id="reddit-input"
                        variant="filled"
                        size="small"
                        placeholder="Search by email address..."
                    />

                )}
            />
        </div>
    )
}
export default PeoplePicker;